// Firebase imports
import initializeFirebaseApp from './FirebaseConfig';

import React from 'react';
import ReactDOM from 'react-dom';
// import * as Sentry from "@sentry/react";
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { reduxStore } from './redux/Reducers';
import { ThemeProvider } from '@material-ui/styles';
import {ThemeProvider as ThemeProviderJSS} from 'react-jss'
import { theme } from './styling/DefaultTheme';
import {aroma} from '@design-system/themes/aroma'
import BourdainStyleProvider from './styling/BourdainStyleProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import thunk from 'redux-thunk';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';
import LoadingIndicator from './shared/components/static/LoadingIndicator';
import { Helmet } from 'react-helmet';
// import { Userpilot } from 'userpilot'

/*
Sentry.init({
    dsn: "https://54be0f2e116545edbfad1e55ef298ffc@o483378.ingest.sentry.io/5534989",
    autoSessionTracking: true,
    integrations: [
        new Integrations.BrowserTracing(),
    ],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
    environment: firebaseConfig.projectId
});

Sentry.setTag("project", "Bourdain");
*/

/*
Userpilot.initialize('7qh1j9');  // Foodpairing account
*/

const store = createStore(reduxStore, applyMiddleware(thunk));

const persistor = persistStore(store);

initializeFirebaseApp(store);
// Add an event listener to catch Embedded Authentication requests


function generateNonce(length = 16) {
  const array = new Uint8Array(length);
  window.crypto.getRandomValues(array);
  return Array.from(array, byte => byte.toString(16).padStart(2, '0')).join('');
}

const nonce = generateNonce();

ReactDOM.render(
  <Router>
      <Provider store={store}>
        <PersistGate loading={<LoadingIndicator />} persistor={persistor}>
          <ThemeProvider theme={theme}>
              <ThemeProviderJSS theme={aroma} nonce={nonce}>
                  <BourdainStyleProvider>
                    <Helmet>
                      <title>flavor.id</title>
                      <meta property="csp-nonce" content={nonce} />
                      <meta
                        httpEquiv="Content-Security-Policy"
                        content={`
                          default-src 'self';
                          font-src
                            'self'
                            https://fonts.gstatic.com
                            https://fonts.googleapis.com;
                          script-src
                            'self'
                            https://cdn.firebase.com
                            https://*.firebaseio.com
                            https://apis.google.com
                            https://accounts.google.com/gsi/client
                            https://googletagmanager.com
                            https://*.googletagmanager.com
                            https://*.google-analytics.com;
                          img-src
                            'self'
                            https://firebasestorage.googleapis.com
                            https://*.google-analytics.com;
                          child-src 'self' https://*.firebaseapp.com https://*.googleusercontent.com;
                          style-src 'self' 'unsafe-inline' ${nonce} https://fonts.googleapis.com;
                          frame-src 'self' https://*.firebaseapp.com https://*.google.com https://*.googleapis.com https://accounts.google.com/gsi/;
                          connect-src
                            'self'
                            https://accounts.google.com/gsi/
                            https://*.googleapis.com
                            https://*.google-analytics.com;
                        `}
                      />
                    </Helmet>
                      <App />
                  </BourdainStyleProvider>
              </ThemeProviderJSS>
          </ThemeProvider>
        </PersistGate>
      </Provider>
  </Router>,
  document.getElementById('react-root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
